import React from "react"
import Layout from "../components/layout"
import Headline from "../components/headline"
import GridTwo from "../components/grid-2"
import axios from "axios"

export const Contact = () => {
  const [name, setName] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [thankyou, setThankyou] = React.useState("")
  const sendEmail = () => {
    if (message === "" || email === "" || name === "" || phone === "") {
      return false
    }

    console.log("phone: ", phone)
    console.log("name: ", name)
    console.log("email: ", email)
    console.log("message: ", message)

    const formData = new FormData()
    formData.append("mail", "yup")
    formData.append(
      "data",
      `Name : <strong>${name}</strong> <br /> Email id : <strong>${email}</strong> <br /> Phone : <strong>${phone}</strong> <br /> Message : ${message}`
    )
    axios
      .post(
        `https://hdegree.co/clients/allianceemailclient/sendgrid-php/email.php`,
        formData
      )
      .then(res => {
        console.log(res)
        console.log(res.data)
        setEmail("")
        setMessage("")
        setName("")
        setPhone("")
        setThankyou("Thank you for contacting us.")
      })

    // http.post <any>
    //   ("https://hdegree.co/clients/allianceemailclient/sendgrid-php/email.php",
    //   formData).subscribe(
    //     val => {
    //       email = ""
    //       message = ""
    //       name = ""
    //       doneornot = "done"
    //     },
    //     response => {
    //       email = ""
    //       message = ""
    //       name = ""
    //       doneornot = "done"
    //     },
    //     () => {
    //       email = ""
    //       message = ""
    //       name = ""
    //       doneornot = "done"
    //     }
    //   )
  }

  return (
    <Layout>
      <Headline
        title="Contact Us"
        excerpt="Alliance World India head quarters are located in the beautiful city of Chandigarh in India. "
      />
      <section
        className="section is-medium"
        style={{ borderBottom: "1px solid #ebebeb" }}
        id="alliance-india"
      >
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Get in touch
          </h3>
          <br />
          <h5
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            style={{ width: "80%" }}
            className="is-size-4 has-text-weight-light"
          >
            We are always happy to hear from you. Please fill in the form below
            for any enquiries, feedback, comments or questions you may have.
          </h5>
          <br />
          <br />
          <section className="section">
            <div
              className="container"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <div class="columns is-variable is-0 is-multiline">
                <div class="column is-12">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name *"
                    onChange={e => setName(e.target.value)}
                    value={name}
                    id=""
                  />
                </div>
                <div class="column is-6">
                  <input
                    type="text"
                    name="Phone"
                    placeholder="Phone *"
                    value={phone}
                    id=""
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
                <div class="column is-6">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email *"
                    value={email}
                    id=""
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div class="column is-6">
                  <label htmlFor="" className="label has-text-weight-light">
                    {" "}
                    Your comments / query *
                  </label>
                  <textarea
                    name="Comment"
                    rows="10"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div
                  class="column is-6"
                  style={{
                    padding: "1rem",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <button
                    type="button"
                    className="button is-outlined is-primary is-medium has-text-weight-light is-uppercase"
                    name=""
                    onClick={() => sendEmail()}
                    value="Submit"
                    id=""
                  >
                    Submit
                  </button>
                </div>
              </div>
              <span style={{ color: "limegreen", fontWeight: "900" }}>
                {thankyou}
              </span>
            </div>
          </section>
          <br />
          <GridTwo
            left="
          <iframe src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13718.81005691883!2d76.8042639!3d30.7267625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x655504bda20090b6!2sAlliance%20World%20India!5e0!3m2!1sen!2sin!4v1580993385458!5m2!1sen!2sin' width='100%' style='height:100%;min-height:450px' frameborder='0' style='border:0;' allowfullscreen=''></iframe>
          "
            right="
            <div class='has-text-weight-light is-size-4'>
            <small>Head Office</small><br/>
          S.C.O 31,<br/>
          Sector 26, Madhya Marg,<br/>
          Chandigarh 160 019, India<br/><br/>

          <small>Phone:</small><br/>
          +91 172 2790838, 2790782<br/><br/>

          <small>Email:</small><br/>
          info@alliance-world.com<br/><br/>

            </div>
          "
          ></GridTwo>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
